






















































import { Component, Vue } from "vue-property-decorator";
import { apiSmsGetConfigDetail, apiSmsSet } from "@/api/marketing/sms";
@Component
export default class SmsEdit extends Vue {
    type: any = 1;
    detail: any = {
        name: '',
        app_key: '',
        status: 1,
        sign: '',
        secret_id: '',
        app_id: '',
        secret_key: ''
    };

    // 提交保存
    onSubmit() {
        apiSmsSet({...this.detail,type: this.type})
            .then((res) => {
                this.$router.go(-1);
                this.$message.success("保存成功!");
            })
            .catch(() => {
                this.$message.error("数据请求失败，刷新重载!");
            });
    }

    // 获取详情
    getNoticeDetail() {
        apiSmsGetConfigDetail({ type: this.type })
            .then((res) => {
                this.detail = res;
            })
            .catch(() => {
                this.$message.error("数据请求失败，刷新重载!");
            });
    }

    created() {
        this.type = this.$route.query.id;
        this.type && this.getNoticeDetail();
    }
}
